.medicationContainer {
  border: 2px solid black;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;

  .headerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .medName {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 10px;
    }

    .removeButton {
      height: 25px;
    }
  }

  .bold {
    font-weight: bolder;
  }
}
