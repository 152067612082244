.consumptionForm {
  display: grid;
  row-gap: 10px;

  .inputContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;

    .label {
      grid-column: 1 / 2;
      text-align: right;
    }

    .unitInput {
      width: 50px;
      margin-right: 5px;
    }

    div {
      margin: 0px 10px;
    }

    .logButton {
      margin: 0px 10px;
      grid-column: 2/3;
      width: 100%;
      max-width: 150px;
    }
  }
}
