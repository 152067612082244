.newMedicationFormContainer {
  border: 2px solid #37752e;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;

  .title {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .rootInputContainer {
    display: grid;
    row-gap: 10px;

    .inputContainer {
      display: grid;
      grid-template-columns: 2fr 4fr;
      column-gap: 10px;
      max-width: 500px;
      align-items: center;

      .label {
        grid-column: 1 / 2;
        display: inline-block;
        text-align: right;
      }

      .input {
        grid-column: 2 / 3;

        input,
        button {
          width: 100%;
          padding: 0px;
        }

        .narrowInput {
          width: 50px;
          margin-right: 6px;
        }
      }
    }
  }
}
