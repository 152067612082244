.consumptionListItem {
  display: flex;
  flex-direction: row;

  .removeButton {
    margin-right: 10px;
  }

  .light {
    font-weight: lighter;
  }
}
